import auth from './auth';
import master from './master';
import pegawai from './pegawai';
import pengguna from './pengguna';
import upload from './upload';
import penerimaan from './penerimaan';

export default {
	auth,
	master,
	pegawai,
	pengguna,
	upload,
	penerimaan,
};
