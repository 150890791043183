import React, { FC } from 'react';
import PropTypes from 'prop-types';
import logo from '../assets/logos/aul_logo-removebg-preview.png';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		// <img
		// 	src={logo}
		// 	alt='Logo'
		// 	style={{
		// 		width: calculatedWidth || 'auto',
		// 		height: calculatedHeight || 'auto',
		// 	}}
		// />

		<svg
			width={height !== 854 && !!height ? height * (2155 / 854) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}
			viewBox='0 0 2155 854'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				transform='translate(563,77)'
				d='m0 0h12l15 2 15 6 10 7 9 8 9 12 7 16 2 9v23l-3 12-6 14-9 12-13 16-11 14-9 11-13 16-10 13-14 17-22 28-11 15-12 16-16 21-12 16-16 21-9 12-14 18-21 28-14 19-14 18-14 19-9 12-2 2 77 1h50v-123l3-12 5-12 8-12 13-13 14-8 10-4 10-2h20l11 2 15 6 12 8 12 12 7 11 7 18 1 12v329l-2 17-5 14-8 13-12 12-13 9-13 5-9 2-9 1h-10l-15-3-12-5-11-7-15-15-9-16-4-14-1-9v-68h-242l-2 5-10 14-20 26-27 36-16 21-8 10-10 10-13 8-10 4-14 3h-14l-14-3-12-5-14-9-9-9-7-11-5-11-4-17v-13l3-15 6-14 12-17 14-18 12-16 16-21 14-19 20-26 11-14 14-19 9-11 10-14 14-19 13-17 12-16 10-13 16-21 14-19 10-13 16-21 15-20 10-13 15-20 32-42 13-18 12-15 8-11 16-21 8-11 11-14 21-28 16-21 15-20 14-19 14-18 9-10 10-7 10-5 14-4z'
				fill='#F91980'
			/>
			<path
				transform='translate(563,77)'
				d='m0 0h12l15 2 15 6 10 7 9 8 9 12 7 16 2 9v23l-3 12-6 14-9 12-13 16-11 14-9 11-13 16-10 13-14 17-22 28-11 15-12 16-16 21-12 16-16 21-9 12-14 18-21 28-14 19-14 18-14 19-9 12-2 2 3 2-11 1h-138l-16 2-15 5-10 5-5 6-1-3 10-14 14-19 13-17 12-16 10-13 16-21 14-19 10-13 16-21 15-20 10-13 15-20 32-42 13-18 12-15 8-11 16-21 8-11 11-14 21-28 16-21 15-20 14-19 14-18 9-10 10-7 10-5 14-4z'
				fill='#03BAD6'
			/>
			<path
				transform='translate(558,345)'
				d='m0 0h20l11 2 15 6 12 8 12 12 7 11 7 18 1 12v329l-2 17-5 14-8 13-12 12-13 9-13 5-9 2-9 1h-10l-15-3-12-5-11-7-15-15-9-16-4-14-1-9v-68h-22v-1l21-1 1-10v-133l1-2-3-1h2v-123l3-12 5-12 8-12 13-13 14-8 10-4z'
				fill='#F35621'
			/>
			<path
				transform='translate(156,573)'
				d='m0 0 1 3-3 7-2 12v11l1 13 5 15 8 14 11 12 7 6 15 8 15 3 31 1 8 1-2 5-10 14-20 26-27 36-16 21-8 10-10 10-13 8-10 4-14 3h-14l-14-3-12-5-14-9-9-9-7-11-5-11-4-17v-13l3-15 6-14 12-17 14-18 12-16 16-21 14-19 20-26 11-14z'
				fill='#03BAD6'
			/>
			<path
				transform='translate(222,532)'
				d='m0 0h156v1l-13 2-9 12-5 9-8 10h-2l-2 4-7 9-8 11-9 12-7 9-7 10-11 14-9 12-11 15-4 7 1 5 3 2 18 1v1l-9 1h-34l-31-1-15-3-15-8-10-9-9-10-9-16-4-13-1-13v-11l3-16 2-3 2-6 9-12 7-9h3l5-6 7-3 14-5 8-2z'
				fill='#B23AA0'
			/>
			<path
				transform='translate(1444,365)'
				d='m0 0h38l17 1 15 3 12 4 13 7 11 9 8 9 7 11 7 14 4 15 1 12v47l-2 15-4 13-8 15-9 12-9 9-14 9-16 6-15 3-9 1h-41l-12-3-8-4-7-8-5-12-1-5v-164l5-10 7-6 6-2zm9 24-7 2-1 27v111l1 12 3 6 5 5 6 3 4 1h28l16-4 14-7 6-5 9-12 6-14 3-15v-54l-4-17-7-14-9-10-9-7-12-5-16-3z'
				fill='#080B0B'
			/>
			<path
				transform='translate(776,363)'
				d='m0 0h25l2 2 20 64 22 69 12 39 10 31 3 10-1 5-18-1-6-4-6-7-5-11-10-30h-86l-1 7-8 24-6 12-4 5-7 4-5 1h-13l-1-5 9-27 14-43 16-49 11-35 14-41 6-10 5-5zm5 35-12 36-21 65-2 6 68 1 3-1-3-11-18-56-9-29-4-11z'
				fill='#0C0D0D'
			/>
			<path
				transform='translate(1738,431)'
				d='m0 0h27l12 2 13 5 10 8 6 10 2 8v18l-4 11-6 8-7 6-10 4-14 3-10 1h-54l5 21 6 11 9 7 9 4 10 2h20l18-4 4-1h9l7 6 4 8v4l-15 5-24 4-17 1-14-1-18-4-15-8-10-9-7-11-4-9-4-17v-32l4-17 8-16 7-8 7-7 12-7 12-4zm1 21-12 3-9 6-7 7-5 11-3 16 1 1h49l14-3 8-4 5-6 2-6v-7l-3-8-7-6-7-3-6-1z'
				fill='#090C0C'
			/>
			<path
				transform='translate(959,435)'
				d='m0 0 12 2 8 3 7 8 3 7 1 80 3 12 6 8 8 4 4 1h17l12-3 10-5 10-7 1-89 3-8 6-7 9-4 12-2 1 2-2 24v93l1 14v12l-11-1-9-4-5-5-3-9v-3l-5 5-8 7-14 8-16 4-13 1-14-2-10-4-6-4-5-5-6-9-4-11-1-10-1-73-1-21-1-8z'
				fill='#070A09'
			/>
			<path
				transform='translate(1228,363)'
				d='m0 0h6l1 173 2 9 5 6 9 4 55 1 8 2 6 4 4 5 2 5v8h-88l-12-3-8-5-7-8-5-13-1-10v-153l2-9 5-9 6-4z'
				fill='#040909'
			/>
			<path
				transform='translate(1892,433)'
				d='m0 0h22l8 4 6 7 9 25 15 46 12 35 2 6 2-3 12-36 16-47 8-23 5-9 10-5h20l-2 6-11 26-15 42-10 29-11 31-7 10-7 4-4 1-18 1h-5l-3-4-12-33-8-24-13-36-8-22-11-26z'
				fill='#0C0D0D'
			/>
			<path
				transform='translate(1317,447)'
				d='m0 0h32l15 1 3 3v47l-9 11-3 3-4-1-3 5h-48l-1-1-1-31v-16l6-10 7-7z'
				fill='#02BAD6'
			/>
			<path
				transform='translate(180,546)'
				d='m0 0m-1 1m-1 1m-4 1 2 3-10 10v-3z'
				fill='#D5A4BE'
			/>
		</svg>
		// <svg
		// 	width={height !== 854 && !!height ? height * (2155 / 854) : width}
		// 	height={width !== 2155 && !!width ? width * (854 / 2155) : height}
		// 	viewBox='0 0 2155 854'
		// 	fill='none'
		// 	xmlns='http://www.w3.org/2000/svg'>
		// 	<path
		// 		d='M985.058 284.2C953.411 284.2 937.587 301.4 937.587 335.8V355H1025.92V415H939.991V673H864.879V415H812V355H864.879V334.6C864.879 300.2 874.894 273.2 894.924 253.6C914.953 233.6 943.195 223.6 979.65 223.6C1008.49 223.6 1031.13 229.4 1047.55 241L1026.52 297.4C1013.7 288.6 999.88 284.2 985.058 284.2Z'
		// 		fill='currentColor'
		// 	/>
		// 	<path
		// 		d='M1193.19 349C1240.46 349 1276.52 360.4 1301.35 383.2C1326.59 405.6 1339.21 439.6 1339.21 485.2V673H1268.3V634C1259.09 648 1245.87 658.8 1228.65 666.4C1211.82 673.6 1191.39 677.2 1167.35 677.2C1143.32 677.2 1122.29 673.2 1104.26 665.2C1086.23 656.8 1072.21 645.4 1062.2 631C1052.58 616.2 1047.78 599.6 1047.78 581.2C1047.78 552.4 1058.39 529.4 1079.62 512.2C1101.26 494.6 1135.11 485.8 1181.18 485.8H1264.1V481C1264.1 458.6 1257.29 441.4 1243.67 429.4C1230.45 417.4 1210.62 411.4 1184.18 411.4C1166.15 411.4 1148.33 414.2 1130.7 419.8C1113.47 425.4 1098.85 433.2 1086.83 443.2L1057.39 388.6C1074.22 375.8 1094.45 366 1118.08 359.2C1141.72 352.4 1166.75 349 1193.19 349ZM1182.98 622.6C1201.81 622.6 1218.43 618.4 1232.85 610C1247.67 601.2 1258.09 588.8 1264.1 572.8V535.6H1186.58C1143.32 535.6 1121.69 549.8 1121.69 578.2C1121.69 591.8 1127.09 602.6 1137.91 610.6C1148.73 618.6 1163.75 622.6 1182.98 622.6Z'
		// 		fill='currentColor'
		// 	/>
		// 	<path
		// 		d='M1585.76 677.2C1552.51 677.2 1522.67 670.2 1496.23 656.2C1469.79 642.2 1449.16 622.8 1434.34 598C1419.51 572.8 1412.1 544.4 1412.1 512.8C1412.1 481.2 1419.51 453 1434.34 428.2C1449.16 403.4 1469.59 384 1495.63 370C1522.07 356 1552.11 349 1585.76 349C1617.41 349 1645.05 355.4 1668.68 368.2C1692.72 381 1710.75 399.4 1722.76 423.4L1665.08 457C1655.87 442.2 1644.25 431.2 1630.23 424C1616.61 416.4 1601.58 412.6 1585.16 412.6C1557.12 412.6 1533.88 421.8 1515.46 440.2C1497.03 458.2 1487.81 482.4 1487.81 512.8C1487.81 543.2 1496.83 567.6 1514.86 586C1533.28 604 1556.72 613 1585.16 613C1601.58 613 1616.61 609.4 1630.23 602.2C1644.25 594.6 1655.87 583.4 1665.08 568.6L1722.76 602.2C1710.35 626.2 1692.12 644.8 1668.08 658C1644.45 670.8 1617.01 677.2 1585.76 677.2Z'
		// 		fill='currentColor'
		// 	/>
		// 	<path
		// 		d='M1787.69 352.6H1862.81V673H1787.69V352.6ZM1825.55 299.8C1811.93 299.8 1800.51 295.6 1791.3 287.2C1782.09 278.4 1777.48 267.6 1777.48 254.8C1777.48 242 1782.09 231.4 1791.3 223C1800.51 214.2 1811.93 209.8 1825.55 209.8C1839.17 209.8 1850.59 214 1859.8 222.4C1869.02 230.4 1873.62 240.6 1873.62 253C1873.62 266.2 1869.02 277.4 1859.8 286.6C1850.99 295.4 1839.57 299.8 1825.55 299.8Z'
		// 		fill='currentColor'
		// 	/>
		// 	<path
		// 		d='M2154.28 655.6C2145.47 662.8 2134.65 668.2 2121.83 671.8C2109.41 675.4 2096.19 677.2 2082.17 677.2C2046.92 677.2 2019.68 668 2000.45 649.6C1981.22 631.2 1971.61 604.4 1971.61 569.2V415H1918.73V355H1971.61V281.8H2046.72V355H2132.65V415H2046.72V567.4C2046.72 583 2050.52 595 2058.13 603.4C2065.75 611.4 2076.76 615.4 2091.18 615.4C2108.01 615.4 2122.03 611 2133.25 602.2L2154.28 655.6Z'
		// 		fill='currentColor'
		// 	/>
		// 	<path
		// 		d='M1824.4 200L1879.83 230.098V290.295L1824.4 320.393L1768.97 290.295V230.098L1824.4 200Z'
		// 		fill='#F35421'
		// 	/>
		// 	<rect x='300' width='256' height='256' rx='64' fill='#46BCAA' />
		// 	<circle cx='128' cy='726' r='128' fill='#4D69FA' />
		// 	<rect x='300' y='355' width='256' height='144' fill='#6C5DD3' />
		// 	<path d='M128 24L238.851 216H17.1488L128 24Z' fill='#FFCF52' />
		// 	<path
		// 		d='M128 307L238.851 367.197V487.59L128 547.787L17.1488 487.59V367.197L128 307Z'
		// 		fill='#F35421'
		// 	/>
		// </svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
