import Api from '../connection';

const title = `pengguna`;

export default {
	findAll(object, currentPage, perPage, filters, OrderBy) {
		return Api.applikasi.get(
			`${title}?param0=${object}&param1=${currentPage}&param2=${perPage}&param3=${filters}&param4=${OrderBy}`,
		);
	},
	findId(id, param0) {
		return Api.applikasi.get(`${title}/${id}?param0=${param0}`);
	},
	create(object, data) {
		return Api.applikasi.post(`${title}/${object}`, data);
	},
	update(object, data) {
		return Api.applikasi.put(`${title}/${object}`, data);
	},
	updateStatus(id, data) {
		return Api.applikasi.patch(`${title}/${id}`, data);
	},
	delete(id, data) {
		return Api.applikasi.patch(`${title}/${id}`, data);
	},
};
