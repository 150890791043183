import axios from 'axios';

const applikasi = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	// withCredentials: true,
	// withXSRFToken: true,
	headers: {
		Accept: 'application/json',
	},
});

applikasi.interceptors.request.use(
	(config) => {
		const token = sessionStorage.getItem('token');
		if (token) {
			config.headers['X-Token'] = token;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

applikasi.interceptors.response.use(
	(response) => {
		// Periksa statecode dalam response data

		if (response.data && response.data.statcode === 20005) {
			// Lakukan pengalihan ke halaman login atau logout
			window.location.href = '/auth-pages/login';
		}
		return response;
	},
	async (error) => {
		if (error.response) {
			if (error.response.status === 401) {
				// Token kedaluwarsa atau tidak valid
				// Lakukan pengalihan ke halaman login atau logout
				window.location.href = '/auth-pages/login';
			} else if (error.response.data && error.response.data.statecode === 20005) {
				// Periksa statecode dalam response error data
				// Lakukan pengalihan ke halaman login atau logout
				window.location.href = '/auth-pages/login';
			}
		}
		return Promise.reject(error);
	},
);

export default {
	applikasi,
};
