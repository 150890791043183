import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
	componentPagesMenu,
	dashboardPagesMenu,
    dashboardMenuUtama,
	demoPagesMenu,
	master,
	gettingStartedPagesMenu,
	pageLayoutTypesPagesMenu,
	anakAsuh,
	penerimaan,
	target,
	mutasiPenerimaan,
    dashboardDonatur,
    dashboardKlasifikasiDonatur,
    home,
} from '../menu';

import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DashboardHeaderCustom from '../pages/_layout/_headers/DashboardHeaderCustom';
import DashboardBookingHeader from '../pages/_layout/_headers/DashboardBookingHeader';
import ProfilePageHeader from '../pages/_layout/_headers/ProfilePageHeader';
import SummaryHeader from '../pages/_layout/_headers/SummaryHeader';
import ProductsHeader from '../pages/_layout/_headers/ProductsHeader';
import ProductListHeader from '../pages/_layout/_headers/ProductListHeader';
import PageLayoutHeader from '../pages/_layout/_headers/PageLayoutHeader';
import ComponentsHeader from '../pages/_layout/_headers/ComponentsHeader';
import FormHeader from '../pages/_layout/_headers/FormHeader';
import ChartsHeader from '../pages/_layout/_headers/ChartsHeader';
import ContentHeader from '../pages/_layout/_headers/ContentHeader';
import UtilitiesHeader from '../pages/_layout/_headers/UtilitiesHeader';
import IconHeader from '../pages/_layout/_headers/IconHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';
import DocumentationHeader from '../pages/_layout/_headers/DocumentationHeader';

const headers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path, element: null },
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: demoPagesMenu.login.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: demoPagesMenu.page404.path, element: null },
	{ path: demoPagesMenu.knowledge.subMenu.grid.path, element: null },
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{
		path: demoPagesMenu.projectManagement.subMenu.list.path,
		element: <DashboardHeader />,
	},
	{ path: demoPagesMenu.pricingTable.path, element: <DashboardHeader /> },
	{
		path: dashboardPagesMenu.dashboardBooking.path,
		element: <DashboardBookingHeader />,
	},
	{
		path: home.home.path,
		element: <ProfilePageHeader />,
	},
	{
		path: dashboardMenuUtama.dashboardUtama.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: dashboardDonatur.dashboardDonatur.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: dashboardKlasifikasiDonatur.dashboardKlasifikasiDonatur.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: demoPagesMenu.appointment.subMenu.calendar.path,
		element: <DashboardBookingHeader />,
	},
	{
		path: demoPagesMenu.appointment.subMenu.employeeList.path,
		element: <DashboardBookingHeader />,
	},
	{
		path: demoPagesMenu.listPages.subMenu.listFluid.path,
		element: <DashboardBookingHeader />,
	},
	{
		path: `${demoPagesMenu.editPages.path}/*`,
		element: <DashboardBookingHeader />,
	},
	{
		path: `${demoPagesMenu.appointment.subMenu.employeeID.path}/*`,
		element: <DashboardBookingHeader />,
	},
	{
		path: `${demoPagesMenu.projectManagement.subMenu.itemID.path}/*`,
		element: <DashboardBookingHeader />,
	},
	{
		path: demoPagesMenu.singlePages.subMenu.fluidSingle.path,
		element: <ProfilePageHeader />,
	},
	{
		path: demoPagesMenu.singlePages.subMenu.boxedSingle.path,
		element: <ProfilePageHeader />,
	},
	{
		path: demoPagesMenu.sales.subMenu.transactions.path,
		element: <ProfilePageHeader />,
	},
	{
		path: demoPagesMenu.chat.subMenu.withListChat.path,
		element: <ProfilePageHeader />,
	},
	{
		path: demoPagesMenu.chat.subMenu.onlyListChat.path,
		element: <ProfilePageHeader />,
	},
	{
		path: `${demoPagesMenu.knowledge.subMenu.itemID.path}/:id`,
		element: <ProfilePageHeader />,
	},
	{
		path: demoPagesMenu.crm.subMenu.dashboard.path,
		element: <ProfilePageHeader />,
	},
	{
		path: demoPagesMenu.crm.subMenu.customersList.path,
		element: <ProfilePageHeader />,
	},
	{
		path: `${demoPagesMenu.crm.subMenu.customerID.path}/:id`,
		element: <ProfilePageHeader />,
	},
	{
		path: dashboardPagesMenu.summary.path,
		element: <SummaryHeader />,
	},
	{
		path: demoPagesMenu.gridPages.subMenu.gridBoxed.path,
		element: <ProductsHeader />,
	},
	{
		path: demoPagesMenu.gridPages.subMenu.gridFluid.path,
		element: <ProductsHeader />,
	},
	{
		path: demoPagesMenu.listPages.subMenu.listBoxed.path,
		element: <ProductListHeader />,
	},
	{
		path: demoPagesMenu.sales.subMenu.salesList.path,
		element: <ProductListHeader />,
	},
	{
		path: demoPagesMenu.sales.subMenu.productsGrid.path,
		element: <ProductListHeader />,
	},
	{
		path: `${demoPagesMenu.sales.subMenu.productID.path}/:id`,
		element: <ProductListHeader />,
	},
	{
		path: `${pageLayoutTypesPagesMenu.asideTypes.path}/*`,
		element: <PageLayoutHeader />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PageLayoutHeader />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PageLayoutHeader />,
	},
	{
		path: `${componentPagesMenu.components.path}/*`,
		element: <ComponentsHeader />,
	},
	{
		path: `${componentPagesMenu.forms.path}/*`,
		element: <FormHeader />,
	},
	{
		path: `${componentPagesMenu.charts.path}/*`,
		element: <ChartsHeader />,
	},
	{
		path: `${componentPagesMenu.content.path}/*`,
		element: <ContentHeader />,
	},
	{
		path: `${componentPagesMenu.utilities.path}/*`,
		element: <UtilitiesHeader />,
	},
	{
		path: `${componentPagesMenu.icons.path}/*`,
		element: <IconHeader />,
	},
	{
		path: `${gettingStartedPagesMenu.gettingStarted.path}/*`,
		element: <DocumentationHeader />,
	},
	{
		path: `${gettingStartedPagesMenu.routes.path}/*`,
		element: <DocumentationHeader />,
	},
	{
		path: `${master.listMaster.subMenu.manajemenPengguna.path}/*`,
		element: <DashboardHeaderCustom />,
	},
	{
		path: `${master.listMaster.subMenu.pegawai.path}/*`,
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.organisasi.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.program.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.lokasi.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: 'master.asrama.path',
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.klasifikasi.subMenu.departemen.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.klasifikasi.subMenu.lag.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.klasifikasi.subMenu.sumberpenerimaan.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.klasifikasi.subMenu.wig.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.kategori.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.referensi.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.akad.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: master.donatur.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: anakAsuh.Dashboard.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: anakAsuh.listAnakAsuh.subMenu.ListAnak.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: `form-anak-asuh/:id`,
		element: <DashboardHeaderCustom />,
	},
	{
		path: `profile/:id`,
		element: <DashboardHeaderCustom />,
	},
	{
		path: penerimaan.penerimaan.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: `form-penerimaan/add`,
		element: <DashboardHeaderCustom />,
	},
	{
		path: target.target.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: mutasiPenerimaan.mutasi.path,
		element: <DashboardHeaderCustom />,
	},
	{
		path: `detail-donatur/:id`,
		element: <DashboardHeaderCustom />,
	},
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
