import Api from '../connection';

export default {
	// PRACTIONERS
	login(data) {
		return Api.applikasi.post(`sign-in`, data);
	},
	token() {
		return Api.applikasi.get(`/auth/practitioner/token`);
	},
	logout() {
		return Api.applikasi.delete('sign-out');
	},
	register(data) {
		return Api.applikasi.post(`sign-up`, data);
	},
	search(object, currentPage, perPage, filters, OrderBy) {
		return Api.applikasi.get(
			`search?param0=${object}&param1=${currentPage}&param2=${perPage}&param3=${filters}&param4=${OrderBy}`,
		);
	},
};
