import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Dayjs } from 'dayjs';
import Icon from './icon/Icon';
import Input from './bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader } from './bootstrap/Modal';
import { master } from '../menu';
import services from '../services';

interface DataItem {
	id: number;
    nik: string;
	nama: string;
	tempat_lahir: string;
	tanggal_lahir: Dayjs;
	agama: number;
	jenis_kelamin: number;
    kontak: string;
    email:string;
	hobi: number;
	perkawinan: number;
	pekerjaan: number;
	alamat: string;
	rt: string;
	rw: string;
	kodepos: string;
    wilayah: number;
    provinsi: string;
    kabupaten: string;
    kecamatan: string;
    kelurahan: string;
	status: number;
}

const Search = () => {
	const refSearchInput = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();
    const [data, setData] = useState<DataItem[]>([]);
	const [searchModalStatus, setSearchModalStatus] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const formik = useFormik({
		initialValues: {
			searchInput: '',
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: async (values) => {
			setSearchModalStatus(true);
            fetchdata();
		},
	});
	useEffect(() => {
		if (formik.values.searchInput) {
			setSearchModalStatus(true);
            fetchdata();
			refSearchInput?.current?.focus();
		}
		return () => {
			setSearchModalStatus(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.searchInput]);
    const fetchdata = async () => {
        setIsLoading(true);
        const filter = `{"xparam0":"${formik.values.searchInput}","xparam1":"","xparam2":"","xparam3":"","xparam4":"","xparam5":"","xparam6":"","xparam7":""}`;
        const orderby = `{"xparam0":"","xparam1":""}`;
        try {
            const response = await services.auth.search(
                'donatur',
                0,
                10,
                filter,
                orderby
            );
            if (response.data.statcode === 0) {
                
                setData(response.data.data);
                setIsLoading(false);
                
            }else{
                setData([]);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            // eslint-disable-next-line no-console
            console.error('Error fetching data: ', error);
            // Handle errors here
        }
    }
	const searchPages: {
		[key: string]: {
			id: string;
			text: string;
			path: string;
			icon: string;
		};
	} = {
		...master.listMaster.subMenu,
		// ...master.content.subMenu,
		// ...master.forms.subMenu,
		// ...master.utilities.subMenu,
		// ...master.icons.subMenu,
		// ...master.charts.subMenu,
	};
	const filterResult = Object.keys(searchPages)
		.filter(
			(key) =>
				searchPages[key].text
					.toString()
					.toLowerCase()
					.includes(formik.values.searchInput.toLowerCase()) ||
				searchPages[key].path
					.toString()
					.toLowerCase()
					.includes(formik.values.searchInput.toLowerCase()),
		)
		.map((i) => searchPages[i]);
	return (
		<>
			<div className='d-flex' data-tour='search'>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label className='border-0 bg-transparent cursor-pointer'>
					<Icon icon='Search' size='2x' color='primary' />
				</label>
				<Input
					id='searchInput'
					type='search'
					className='border-0 shadow-none bg-transparent'
					placeholder='Search Donatur...'
					onChange={formik.handleChange}
					value={formik.values.searchInput}
					autoComplete='off'
				/>
			</div>
			<Modal
				setIsOpen={setSearchModalStatus}
				isOpen={searchModalStatus}
				isStaticBackdrop
				isScrollable
				data-tour='search-modal'>
				<ModalHeader setIsOpen={setSearchModalStatus}>
					<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>
					<Input
						ref={refSearchInput}
						name='searchInput'
						className='border-0 shadow-none bg-transparent'
						placeholder='Search...'
						onChange={formik.handleChange}
						value={formik.values.searchInput}
					/>
				</ModalHeader>
				<ModalBody>
					<table className='table table-hover table-modern caption-top mb-0'>
						<caption>Results: {data.length}</caption>
						<thead className='position-sticky' style={{ top: -13 }}>
							<tr>
								<th scope='col'>Donatur</th>
							</tr>
						</thead>
						<tbody>
							{data.length ? (
								data.map((item) => (
									<tr
										key={item.id}
										className='cursor-pointer'
										onClick={() => {
                                            setSearchModalStatus(false);
											navigate(`../detail-donatur/${item.id}`);
										}}>
										<td>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-shrink-0'>
                                                <Icon
                                                    icon='AccountCircle'
                                                    size='lg'
                                                    className='me-2'
                                                    color='primary'
                                                />
                                            </div>
                                            <div className='flex-grow-1'>
                                                <div className='fs-6 fw-bold'>
                                                    {item.nama}
                                                </div>
                                                <div className='text-muted'>
                                                    <Icon icon='Label' />{' '}
                                                    <small>{item.nik}</small>
                                                </div>
                                            </div>
                                        </div>
										</td>
									</tr>
								))
							) : (
								<tr className='table-active'>
									<td>No result found for query "{formik.values.searchInput}"</td>
								</tr>
							)}
						</tbody>
					</table>
				</ModalBody>
			</Modal>
		</>
	);
};

export default Search;
