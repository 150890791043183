import React, { SVGProps } from 'react';

const SvgCustomUsa = (props: SVGProps<SVGSVGElement>) => (
	// <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" 
    // viewBox="0 0 512 512">
    // <g fill-rule="nonzero">
    // <path fill="#fff" d="M2.63.09h41.09c1.39 0 2.53 1.15 2.53 2.54v27.04c0 1.39-1.13 2.53-2.52 2.54H2.61a2.553 2.553 0 0 1-2.52-2.54V2.63C.09 1.24 1.24.09 2.63.09z"/>
    // <path fill="red" d="M2.72.18h40.91c1.44-.04 2.53 1.1 2.53 2.54v13.43H.18V2.72C.18 1.28 1.27.14 2.72.18z"/></g>
    // </svg>

	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 512 512'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			style={{
				fill: 'red', // Warna merah
			}}
			d='M0 0h512v256H0z'
		/>
		<path
			style={{
				fill: 'white', // Warna putih
			}}
			d='M0 256h512v256H0z'
		/>
	</svg>
);

export default SvgCustomUsa;
